import { createFileRoute } from "@tanstack/react-router";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { useTronAccount } from "@/hooks/useTronWeb";
import { Progress } from "@/components/ui/progress";
import { ConnectButton } from "@/components/connectButton";
import { OrderForm } from "@/components/orderForm";
import { OrdersTable } from "@/components/ordersTable";
import { Skeleton } from "@/components/ui/skeleton";
import { LightningBoltIcon } from "@radix-ui/react-icons";
import { Gauge } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useMarketResources } from "@/hooks/useResources";

export const Route = createFileRoute("/")({
	component: HomeComponent,
});

function MarketResourcesSection() {
	const { data: resources } = useMarketResources();
	const { t } = useTranslation();
	return (
		<div className="space-y-3">
			<CardTitle className="pt-2 md:pt-0">{t("marketResourcesAPI")}</CardTitle>
			<div className="grid grid-cols-2 gap-3">
				<div className="flex flex-col rounded-md bg-secondary px-3 py-2 shadow-md transition-colors hover:bg-secondary/80">
					<span className="text-muted-foreground text-sm">
						{t("availableEnergy")}
					</span>
					<span className="inline-flex items-center gap-2 font-medium text-lg text-white">
						{resources?.totalEnergy.toLocaleString()}
						<LightningBoltIcon className="h-5 w-5" />
					</span>
				</div>
				<div className="flex flex-col rounded-md bg-secondary px-3 py-2 shadow-md transition-colors hover:bg-secondary/80">
					<span className="text-muted-foreground text-sm">
						{t("availableBandwidth")}
					</span>
					<span className="inline-flex items-center gap-2 font-medium text-lg text-white">
						{resources?.totalBandwidth.toLocaleString()}
						<Gauge className="h-5 w-5" />
					</span>
				</div>
			</div>
		</div>
	);
}

function HomeComponent() {
	const { wallet, connected } = useWallet();
	const { data: tronAccount, isLoading, isError } = useTronAccount();
	const { t } = useTranslation();

	const renderAccountInfo = () => {
		if (isLoading) {
			return (
				<>
					<CardHeader>
						<CardTitle>{t("marketResourcesAPI")}</CardTitle>
						<div className="grid h-[80px] grid-cols-2 gap-3">
							<div className="flex flex-col rounded-md bg-secondary p-2 shadow-md">
								<span className="text-muted-foreground text-sm">
									{t("availableEnergy")}
								</span>
								<span className="inline-flex items-center gap-1 font-medium text-lg text-white">
									<Skeleton className="h-4 w-20" />
									<LightningBoltIcon className="h-4 w-4" />
								</span>
							</div>
							<div className="flex flex-col rounded-md bg-secondary p-2 shadow-md">
								<span className="text-muted-foreground text-sm">
									{t("availableBandwidth")}
								</span>
								<span className="inline-flex items-center gap-1 font-medium text-lg text-white">
									<Skeleton className="h-4 w-16" />
									<Gauge className="h-4 w-4" />
								</span>
							</div>
						</div>
						<Skeleton className="mt-4 h-4 w-full max-w-[250px]" />
						<Skeleton className="mt-2 h-6 w-full max-w-[200px]" />
					</CardHeader>
					<CardContent>
						<div className="grid grid-cols-2 gap-6 rounded-md bg-secondary px-4 py-2 text-sm">
							{[0, 1].map((index) => (
								<div key={index} className="flex flex-col gap-1">
									<Skeleton className="h-4 w-full max-w-[100px]" />
									<Skeleton className="mt-1 h-3 w-full max-w-[150px]" />
									<Skeleton className="mt-2 h-2 w-full" />
									<Skeleton className="mt-2 h-3 w-full max-w-[120px]" />
								</div>
							))}
						</div>
					</CardContent>
				</>
			);
		}

		if (isError) {
			return <div>{t("errorLoadingAccountInfo")}</div>;
		}

		return (
			<CardContent className="flex flex-col gap-4">
				<MarketResourcesSection />

				{!connected && <Separator className="my-1" />}

				{connected && tronAccount ? (
					<>
						<div className="space-y-4">
							<CardTitle>{t("wallet")}</CardTitle>
							<div className="font-semibold text-primary">
								{tronAccount?.available.toLocaleString()} TRX{" "}
								<span className="text-muted-foreground text-sm">
									/ {tronAccount?.balance.toLocaleString()} TRX
								</span>
							</div>
							<div className="grid grid-cols-2 gap-6 rounded-md bg-secondary px-4 py-2 text-sm shadow-md">
								<div className="flex flex-col gap-1">
									<div>
										<p className="font-bold">{t("energy")}</p>
										<p className="text-xs">
											{(
												tronAccount?.resource.energy.total -
												tronAccount.resource.energy.used
											).toLocaleString()}{" "}
											/ {tronAccount?.resource.energy.total.toLocaleString()}
										</p>
									</div>
									<Progress
										value={
											((tronAccount?.resource.energy.total -
												tronAccount?.resource.energy.used) /
												tronAccount?.resource.energy.total) *
											100
										}
									/>
									<p className="mt-1 text-muted-foreground text-xs">
										{t("delegated")}:{" "}
										{tronAccount?.energy_delegated_res.toLocaleString() || 0}
									</p>
								</div>
								<div className="flex flex-col gap-1">
									<div>
										<p className="font-bold">{t("bandwidth")}</p>
										<p className="text-xs">
											{(
												tronAccount?.resource.bandwidth.total -
												tronAccount.resource.bandwidth.used
											).toLocaleString()}{" "}
											/ {tronAccount?.resource.bandwidth.total.toLocaleString()}
										</p>
									</div>
									<Progress
										value={
											((tronAccount?.resource.bandwidth.total -
												tronAccount?.resource.bandwidth.used) /
												tronAccount?.resource.bandwidth.total) *
											100
										}
									/>
									<p className="mt-1 text-muted-foreground text-xs">
										{t("delegated")}:{" "}
										{tronAccount?.bandwidth_delegated_res.toLocaleString() || 0}
									</p>
								</div>
							</div>
						</div>
					</>
				) : (
					<div className="relative flex h-40 w-full flex-col items-center justify-center rounded-lg bg-secondary/30 backdrop-blur-sm">
						<div className="absolute">
							<div className="h-32 w-64 animate-pulse rounded-full bg-primary opacity-30 blur-3xl filter" />
						</div>
						<ConnectButton className="relative z-10" size="lg" />
					</div>
				)}
			</CardContent>
		);
	};

	return (
		<div className="space-y-4">
			<div className="grid grid-cols-1 gap-4 md:grid-cols-layout ">
				<Card className="flex h-full flex-col justify-between overflow-y-auto md:h-[880px]">
					<div className="h-full">{renderAccountInfo()}</div>

					<Separator />

					<div>
						<CardContent className="space-y-4">
							<CardTitle>{t("orderForm")}</CardTitle>
							<OrderForm
								walletAddress={wallet?.adapter.address || ""}
								connected={connected}
							/>
						</CardContent>
					</div>
				</Card>

				<OrdersTable />
			</div>
		</div>
	);
}
